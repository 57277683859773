<template>
  <div class="top">
    <div class="left" @click="index">
      <div class="logo"></div>
    </div>
    <div class="right" @click.stop="show = true">
      <div class="more"><img src="~@/assets/img/more.png" alt="" /></div>
    </div>
  </div>
  <div style="width: 100%; height: calc(100vh); background-color: #ffffff">
    <div class="APP_logo">
      <img class="img" src="~@/assets/img/logo.png" alt="" />
    </div>
    <div class="info">
      <div class="txt">
        <p>{{ lang.v.warning5 }}</p>
      </div>
    </div>
    <div class="login">
      <div class="login_logo">
        <img src="~@/assets/img/Apple_login.png" alt="" />
      </div>
      <div class="login_text">{{ lang.v.Apple_login }}</div>
    </div>
    <div class="login">
      <div class="login_logo">
        <img src="~@/assets/img/google_login.png" alt="" />
      </div>
      <div class="login_text">{{ lang.v.Google_login }}</div>
    </div>
    <div class="login" @click="gologin">
      <div class="login_logo">
        <img src="~@/assets/img/emile_login.png" alt="" />
      </div>
      <div class="login_text">{{ lang.v.Email_login }}</div>
    </div>
    <div class="agreement">
      {{ lang.v.text12 }}
      <el-link
        class="linkTxt"
        :underline="false"
        @click="yinsi"
        type="success"
      >{{ lang.v.Privacy }}</el-link
      >
      &
      <el-link
        class="linkTxt"
        :underline="false"
        @click="yonghu"
        type="success"
      >{{ lang.v.Agreement }}</el-link
      >
    </div>
  </div>
  <van-popup
    v-model:show="show"
    position="right"
    :style="{ width: '80%', height: '100%' }"
  >
    <div class="top_menu">
      <div class="left" @click="index">
        <div class="logo"><img src="~@/assets/img/logo_top.png" alt="" /></div>
      </div>
      <div class="right" @click="more">
        <div class="more"><img src="~@/assets/img/quxiao.png" alt="" /></div>
      </div>
    </div>
    <div
      v-if="userinfo == null ? false : true"
      style="
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 0.5rem;
      "
    >
      <div class="nav">
        <div class="left">
          <div class="picture"><img :src="userinfo.avatar" alt="" /></div>
          <div class="userId">UID:{{ userinfo.userId }}</div>
        </div>
        <div class="right">
          <img src="~@/assets/img/exit.png" alt="" @click="loginout" />
        </div>
      </div>
    </div>
    <div
      style="
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: calc(100vh - 1.13rem);
      "
    >
      <van-cell-group :border="false">
        <van-cell
          v-if="userinfo == null ? true : false"
          to="/login"
          :border="false"
          title-style="color:rgba(237, 147, 30, 1)"
          :title="title_1"
          is-link
        />
        <van-cell :border="false" to="/" :title="title_2" is-link />
        <van-cell :border="false" to="/wallet" :title="title_3" is-link />
        <van-cell
          :border="false"
          @click="intelligent"
          :title="title_4"
          is-link
        />
        <van-cell :border="false" to="/copyright" :title="title_5" is-link />
        <van-cell :border="false" to="/community" :title="title_6" is-link />
        <van-cell :border="false" to="/aboutus" :title="title_7" is-link />
        <!-- <van-cell
          :border="false"
          v-if="userinfo == null ? false : true"
          @click="loginout"
          title="退出登录"
          is-link
        /> -->
        <!-- <van-cell v-if="userinfo == null ? false : true" :border="false" title="订单记录" is-link />
            <van-cell v-if="userinfo == null ? false : true" :border="false" title="团队后台" is-link /> -->
      </van-cell-group>
    </div>
  </van-popup>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { personal, logout, market } from '@/api/fetcher';
import { clearLocalUser } from '@/utils/memory.ts';
import router from '@/router';
import { lang } from '@/lang';
import dataCenter from '@/dataCenter';
import { useRoute } from 'vue-router';
const route = useRoute();

const title_1 = lang.v.title_1;
const title_2 = lang.v.title_2;
const title_3 = lang.v.title_3;
const title_4 = lang.v.title_4;
const title_5 = lang.v.title_5;
const title_6 = lang.v.title_6;
const title_7 = lang.v.title_7;

const yinsi = () => {
  router.push('/privacyPolicy');
};
const yonghu = () => {
  router.push('/userAgreement');
};

const show = ref(false);

const gologin = () => {
  router.push('/emailLogin');
};
const intelligent = () => {
  if (!localStorage.getItem('userInfo')) {
    router.push('/intelligent');
  } else {
    if (
      userinfo.value.talentCardType == 0 ||
      userinfo.value.talentCardType == null
    ) {
      router.push('/intelligent');
    } else {
      sessionStorage.setItem(
        'intelligent_id',
        JSON.stringify(userinfo.value.talentCardType)
      );
      router.push('/become_intelligent');
    }
  }
};

const allUsers = ref([]);
if (!localStorage.getItem('allUsers')) {
  allUsers.value = [];
} else {
  allUsers.value = JSON.parse(localStorage.getItem('allUsers'));
}

const loginout = () => {
  logout().then(() => {
    clearLocalUser();
    router.push('/login');
  });
};
const more = () => {
  show.value = false;
}; // 进入菜单页面
const index = () => {
  router.push('/');
}; // 进入首页
const userinfo = ref({});

onMounted(() => {
  // 获取 code 参数的值
  const query = route.query;
  if (query?.code) {
    market({
      code: String(query.code),
    }).then(res => {
      if (!res) {
        return;
      }
      window.localStorage.setItem('access_token', res.tokenInfo.access_token);
      window.localStorage.setItem('refresh_Token', res.tokenInfo.refresh_token);
      personal().then(res => {
        if (!res) {
          clearLocalUser();
        } else {
          dataCenter.user.data = res;
          history.replaceState(
            null,
            '',
            window.location.pathname + window.location.hash
          );
          // 刷新页面
          router.replace('/index');
        }
      });
    });
  }
});
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;
  position: fixed;
  top: 0;
  box-shadow: 0rem 0.16rem 0.2rem 0rem rgba(237, 147, 30, 0.05);
  background-color: rgba(255, 255, 255, 0.8);

  .left {
    display: flex;

    .logo {
      width: 0.97rem;
      height: 0.26rem;
      margin-left: 0.14rem;
      background: url(~@/assets/img/logo_top.png) no-repeat;
      background-size: 100% 100%;
    }
  }

  .right {
    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.14rem;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

:deep(.van-dropdown-menu) {
  box-shadow: none;
  height: 0.46rem;

  .van-dropdown-menu__bar {
    box-shadow: none;
    height: 0.46rem;
  }
}

.APP_logo {
  width: 1.81rem;
  height: 0.44rem;
  margin: auto;
  padding-top: 2.24rem;
  display: flex;
  img {
    width: 100%;
    height: 0.44rem;
  }
}

.top_menu {
  width: 100%;
  display: flex;
  padding-top: 0.22rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);

  .left {
    display: flex;
    margin-left: calc(3.75% + 0.12rem);

    .logo {
      width: 0.97rem;
      height: 0.26rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    .more {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: calc(3.75% + 0.12rem);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.nav {
  width: 92.5%;
  display: flex;
  height: 0.5rem;
  margin: auto;
  justify-content: space-between;

  .left {
    display: flex;

    .picture {
      width: 0.32rem;
      height: 0.32rem;
      margin-left: 0.12rem;
      margin-top: 0.09rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .userId {
      height: 0.5rempx;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      line-height: 0.5rem;
      margin-left: 0.12rem;
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .right {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.02rem;
    margin-top: 0.13rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

:deep(.van-cell-group) {
  width: 92.5%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border: none !important;

  .van-cell {
    background-color: rgba(255, 255, 255, 0.8);

    .van-badge__wrapper {
      color: #000;
    }
  }
}

.APP_name {
  width: 100%;
  text-align: center;
  height: 0.25rem;
  font-size: 0.18rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #444444;
  margin-top: 0.12rem;
  line-height: 0.25rem;
}

.info {
  width: 82.93%;
  margin: auto;
  padding-top: 0.2rem;

  .txt {
    text-align: center;
    margin-bottom: 0.2rem;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.16rem;
    padding-bottom: 0.2rem;
  }
}

.login {
  width: 82.93%;
  margin: auto;
  height: 0.48rem;
  border-radius: 0.27rem;
  border: 0.01rem solid rgba(133, 140, 159, 0.15);
  display: flex;
  margin-top: 0.2rem;

  .login_logo {
    width: 0.32rem;
    height: 0.32rem;
    margin-top: 0.08rem;
    margin-left: 0.27rem;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .login_text {
    line-height: 0.48rem;
    margin-left: 7.07%;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 0.16rem;
    color: #444444;
  }
}

:deep(.el-form-item__content) {
  align-items: center;

  .el-checkbox {
    width: 100%;
    height: 0.18rem;
    // border: $bor;
    // margin-top: 0.21rem;

    .el-checkbox__input {
      width: 0.2rem;
      height: 0.2rem;

      .el-checkbox__inner {
        border-radius: 0.14rem;
        width: 0.2rem;
        height: 0.2rem;
        border: 0.02rem solid #bdc1cb;
      }

      .el-checkbox__inner::after {
        width: 0.05rem;
        height: 0.09rem;
        left: 0.045rem !important;
        top: 0.005rem !important;
        border-bottom: 0.025rem solid white;
        border-right: 0.025rem solid white;
      }
    }

    .el-checkbox__label {
      width: 2.77rem;
      height: 0.18rem;
      line-height: 0.18rem;
      color: #858c9f;
      font-size: 0.14rem;
      padding-left: 0.06rem;

      .el-link {
        color: #ed931e;

        .el-link__inner {
          font-size: 0.14rem;
        }
      }
    }
  }
}

.agreement {
  width: 82.93%;
  margin: auto;
  text-align: center;
  margin-top: 0.2rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.14rem;
  color: #858c9f;
  line-height: 0.18rem;

  :deep(.el-link) {
    color: #ed931e !important;
  }
}
</style>
